import styled from 'styled-components';

export const DeckContainer = styled.div`
  left: 0;
  width: 100%;
  height: 100%;
  position: relative;
  padding-bottom: 56.1987%;
  margin: 10px auto;
`;
